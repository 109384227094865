<script>
	import { createEventDispatcher, onDestroy } from 'svelte';
	const dispatch = createEventDispatcher();
    const close = () => dispatch('close');
	let modal;
	const handle_keydown = e => {
		if (e.key === 'Escape') {
			close();
			return;
		}
		if (e.key === 'Tab') {
			// trap focus
			const nodes = modal.querySelectorAll('*');
			const tabbable = Array.from(nodes).filter(n => n.tabIndex >= 0);

			let index = tabbable.indexOf(document.activeElement);
			if (index === -1 && e.shiftKey) index = 0;

			index += tabbable.length + (e.shiftKey ? -1 : 1);
			index %= tabbable.length;

			tabbable[index].focus();
			e.preventDefault();
		}
	};

	const previously_focused = typeof document !== 'undefined' && document.activeElement;

	if (previously_focused) {
		onDestroy(() => {
			previously_focused.focus();
		});
    }
</script>

<svelte:window on:keydown={handle_keydown}/>

<div class="modal-background" on:click={close}></div>

<div class="modal bg-white text-gray-800 p-1 rounded-lg" role="dialog" aria-modal="true" bind:this={modal}>
    <div>Claim your spot using the form below</div>
    <div>
        <iframe width="100%" height="640" src="https://summit.beyondmethods.com/coming-soon/" title="Book your spot at the summit" frameborder="0"></iframe>
    </div>

	<slot></slot>

	<!-- svelte-ignore a11y-autofocus -->
</div>

<style>.modal-background{position:fixed;top:0;left:0;width:100%;height:100%;background:rgba(85,102,119,.53)}.modal{position:absolute;left:50%;top:50%;width:calc(100vw - 6em);max-width:64em;max-height:calc(100vh - 4em);overflow:auto;transform:translate(-50%,-50%)}</style>
