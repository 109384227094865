<style>.bgimg{background-image:url(/images/bg-beyondmethods.jpg);background-repeat:no-repeat}</style>

<div class="bgimg bg-cover bg-gray-500 responsive" >
    <div class="flex h-screen justify-center items-center">
        <box class="card bg-blue-500 shadow-lg px-20 rounded-lg mx-4 sm:mx-10 "> 
            <slot>
              <!-- optional fallback -->
            </slot>
        </box> 
    </div>
</div>

<footer class="bg-indigo-100 text-indigo-900 flex justify-center px-4 py-1 inset-x-0 bottom-0 fixed">
  <div>
      &copy; <em id="yeardate"></em> Beyond Methods - All Rights Reserved
  </div>
  
  <script>
    var d = new Date();
    var n = d.getFullYear();
    document.getElementById("yeardate").innerHTML = n ;
  </script>
</footer>

